import http from "./http"

export async function getLookupApi({ token }) {
    try {
        const payload = { 
            profile_id: 715
        }
        const response = await http(true).post('/v1_4/custom/pco/lookup/list', payload, {
            headers: {
                token
            }
        })
        if (response.code !== '200') throw 'error get lookup'
        return response?.data
    } catch (error) {
        throw error
    }
}

export async function getResult({ task_id, token }) {
    try {
        const payload = { 
            profile_id: 715,
            task_id,
        }
        const response = await http(true).post('/v1_4/custom/pco/result/get', payload, {
            headers: {
                token
            }
        })
        if (response.code !== '200') throw 'error get lookup'
        return response?.data
    } catch (error) {
        throw error
    }
}

export async function saveServiceTreatmentApi({ task_id, service_treatment, token }) {
    try {
        const payload = { 
            profile_id: 715,
            task_id,
            service_treatment,
        }
        const response = await http(true).post('/v1_4/custom/pco/result/treatment', payload, {
            headers: {
                token
            }
        })
        if (response.code !== '200') throw 'error save service treatment'
        return response?.data
    } catch (error) {
        throw error
    }
}

export async function saveRecommendationApi({ task_id, recomendation, is_checked, token, user_name }) {
    try {
        const payload = { 
            profile_id: 715,
            task_id,
            recomendation,
            is_checked,
            user_name,
        }
        const response = await http(true).post('/v1_4/custom/pco/result/recommendation', payload, {
            headers: {
                token
            }
        })
        if (response.code !== '200') throw 'error save recommendation'
        return response?.data
    } catch (error) {
        throw error
    }
}

export async function saveResultAllApi({ task_id, recomendation, service_treatment, is_checked, token, user_name }) {
    try {
        const payload = { 
            profile_id: 715,
            task_id,
            recomendation,
            service_treatment,
            is_checked,
            user_name,
        }
        const response = await http(true).post('/v1_4/custom/pco/result/all', payload, {
            headers: {
                token
            }
        })
        if (response.code !== '200') throw response
        return response?.data
    } catch (error) {
        throw error
    }
}