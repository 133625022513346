import { getLookupApi } from '../../api/request'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: null,
    error: null,
}

const getLookupAction = createAsyncThunk('/v1_4/custom/pco/lookup/list', async (data, thunkAPI) => {
    try {
        const { token } = data
        const response = await getLookupApi({ token })
        return response
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

const slice = createSlice({
    name: 'menu-privilage',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.data = null
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLookupAction.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getLookupAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action?.payload
            })
            .addCase(getLookupAction.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.payload
            })
    },
})

const { reset } = slice.actions
const reducer = slice.reducer

const lookupSlice = {
    reducer,
    getLookupAction,
    reset,
}

export default lookupSlice