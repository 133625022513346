import React, { memo } from 'react'
import { IC_SAVING } from '../assets'
import { 
    Flex, 
    Image, 
    Text, 
} from '@chakra-ui/react'

const Saving = () => {
    return (
        <Flex
            alignItems='center'
            gap='3px'
            width='full'
            justifyContent='end'
        >
            <Image 
                src={IC_SAVING} 
                width='16px' 
                height='16px' 
            />
            <Text
                fontSize='11px'
                fontWeight='500'
                color='#909094'
            >Menyimpan...</Text>
        </Flex>
    )
}

export default memo(Saving)