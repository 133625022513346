import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: []
}

const slice = createSlice({
    name: 'recommendation',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            console.log({state})
            state.data = action?.payload
        },
        changeForm: (state, action) => {
            const { index, name, value } = action?.payload
            let area = state.data[index]
            console.log({state})
            switch(name) {
                case 'area':
                    if (value !== '' || value === null) {
                        area[name]['value'] = value?.toUpperCase()
                    } else {
                        area[name]['value'] = ''
                        area['date']['value'] = ''
                        area['detail_recomendation']['value'] = ''
                        area['status_recomendation']['value'] = ''
                        area['verificator']['value'] = ''
                        area['closing_date']['value'] = ''
                    }
                    break
                case 'date':
                    area[name]['value'] = value
                    break
                case 'detail_recomendation':
                    area[name]['value'] = value
                    break
                case 'status_recomendation':
                    area[name]['value'] = value
                    area['verificator']['value'] = ''
                    area['closing_date']['value'] = ''
                    break
                case 'verificator':
                    area[name]['value'] = value
                    break
                case 'closing_date':
                    area[name]['value'] = value
                    break
                default:
                    break
            }
            let oldData = state.data
            oldData[index] = area
            return {
                ...state,
                data: oldData,
            }
        },
    },
})

const { set, changeForm } = slice.actions
const reducer = slice.reducer

const recommendationSlice = {
    reducer,
    set,
    changeForm,
}

export default recommendationSlice