import { configureStore } from '@reduxjs/toolkit'
import lookupSlice from './slices/lookupSlice'
import recommendationSlice from './slices/recommendationSlice'

export const store = configureStore({
    reducer: {
        lookup: lookupSlice.reducer,
        recommendation: recommendationSlice.reducer,
    },
})
