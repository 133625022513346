import { 
    useCallback, 
    useEffect, 
    useState, 
} from 'react'
import './App.css'
import SuccessModal from './components/SuccessModal'
import ServiceTreatmentTab from './components/ServiceTreatmentTab'
import RecomendationTab from './components/RecomendationTab'
import ConflictModal from './components/ConflictModal'
import { BiRefresh } from 'react-icons/bi'
import { useMutation } from 'react-query'
import { format } from 'date-fns'
import { 
    getLookupApi, 
    getResult, 
    saveResultAllApi, 
} from './api/request'
import { 
    Container, 
    useDisclosure, 
    Tabs, 
    TabList, 
    TabPanels, 
    Tab, 
    TabPanel,
    Center,
    Spinner,
    Text,
    Button,
    Icon,
    Stack,
    Flex,
    Tag,
    Image,
} from '@chakra-ui/react'
import { IMG_LOGO_PCO, IMG_TASK_DONE } from './assets'

function App() {
    const modalSuccessDisclosure = useDisclosure()
    const modalConflictDisclosure = useDisclosure()
    
    let params = new URL(document.location).searchParams

    const [validate, setValidate] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)
    const [forms, setForms] = useState([ 
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
        {
            area: {
                label: 'Area',
                mandatory: false,
                value: '',
            },
            date: {
                label: 'Tanggal',
                mandatory: false,
                value: '',
            },
            detail_recomendation: {
                label: 'Detail Rekomendasi',
                mandatory: false,
                value: '',
            },
            status_recomendation: {
                label: 'Status Rekomendasi',
                mandatory: false,
                value: '',
            },
            verificator: {
                label: 'Verificator',
                mandatory: false,
                value: '',
            },
            closing_date: {
                label: 'Tanggal Closing',
                mandatory: false,
                value: '',
            },
        },
    ])
    const [area, setArea] = useState([
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
        {
            area: '',
            temuanPest: '',
            spesies: [
                { 
                    selected: false,
                    name: 'tikus', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kecoa', 
                    tangkapanMax: 200, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'nyamuk', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'semut', 
                    tangkapanMax: 50, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'lalat', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'laba laba', 
                    tangkapanMax: null, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cicak', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'burung', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'anjing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ular', 
                    tangkapanMax: 30, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'kucing', 
                    tangkapanMax: 20, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'ribollium sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Lasioderma sp', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Ephestia', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Night Insect', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Kelelawar', 
                    tangkapanMax: 100, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Trogoderma variabile', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Cryptolestes (Flat grain beetle)', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
                { 
                    selected: false,
                    name: 'Sitophilus sp.', 
                    tangkapanMax: 150, 
                    tangkapan: [], 
                    action: '',
                    bahanAktif: '',
                    satuanBahanAktif: '',
                    jumlahBahanAktif: '',
                },
            ],
            action: '',
            bahanAktif: '',
            satuanBahanAktif: '',
            jumlahBahanAktif: '',
        },
    ])

    const lookup = useMutation(getLookupApi, {
        onSuccess: resp => {
            const spesies = resp?.spesies?.lookup_value
            let tempArea = [...area]
            tempArea = tempArea?.map(v => {
                let newValue = v
                newValue.spesies = spesies?.map(vSpesies => {
                    return {
                        selected: false,
                        tangkapan: [], 
                        action: '',
                        bahanAktif: '',
                        satuanBahanAktif: '',
                        jumlahBahanAktif: '',
                        tangkapanMax: 100,
                        ...vSpesies,
                    }
                })
                return newValue
            })
            setArea(tempArea)
            let params = new URL(document.location).searchParams
            let task_id = params.get('task_id')
            let token = params.get('token')
            if (!task_id) return
            setTimeout(() => {
                result?.mutate({ task_id: Number(task_id), token })
            }, 1)
        },
        onError: (resp) => {
            console.log({errorApiLookup: resp})
        }
    })
    const result = useMutation(getResult, {
        onSuccess: resp => {
            let resultServiceTreatment = resp?.service_treatment
            let resultRecomendation = resp?.recommendation?.filter(v => v?.status_recomendation === 'open')
            let tempServiceTreatment = [...area]
            let tempRecommendation = []

            console.log({resultRecomendation})

            resultServiceTreatment?.map((v, i) => {
                tempServiceTreatment[i]['area'] = v?.area
                tempServiceTreatment[i]['temuanPest'] = v?.temuan_pest === true
                    ?   {
                            label: "Ada",
                            value: "ada",
                        } 
                    :   {
                            label: "Tidak",
                            value: "tidak",
                        }
                
                if (v?.temuan_pest === true) {
                    v?.species?.map(x => {
                        const index = tempServiceTreatment[i]['spesies']?.findIndex(s => s?.id === x?.id)
                        tempServiceTreatment[i]['spesies'][index]['selected'] = true
                        tempServiceTreatment[i]['spesies'][index]['action'] = x?.action
                        tempServiceTreatment[i]['spesies'][index]['tangkapan'] = x?.tangkapan?.map(tangkapan => {
                            return { value: tangkapan?.toString() }
                        })
                        if (tempServiceTreatment[i]['spesies'][index]['tangkapan']?.length < tempServiceTreatment[i]['spesies'][index]['tangkapanMax']) {
                            tempServiceTreatment[i]['spesies'][index]['tangkapan']?.push({value: ''})
                        }
                        tempServiceTreatment[i]['spesies'][index]['bahanAktif'] = {
                            value: x?.bahan_aktif,
                            label: x?.bahan_aktif_description,
                        }
                        tempServiceTreatment[i]['spesies'][index]['satuanBahanAktif'] = x?.jumlah_bahan_aktif?.toString()?.replaceAll('.', ',')
                        switch(x?.satuan_bahan_aktif) {
                            case 'gram':
                                tempServiceTreatment[i]['spesies'][index]['jumlahBahanAktif'] = { value: 'gram', label: 'Gram'}
                                break
                            case 'ml':
                                tempServiceTreatment[i]['spesies'][index]['jumlahBahanAktif'] = { value: 'ml', label: 'ML'}
                                break
                            case 'buah':
                                tempServiceTreatment[i]['spesies'][index]['jumlahBahanAktif'] = { value: 'buah', label: 'Buah'}
                                break
                        }
                    })
                } else {
                    tempServiceTreatment[i]['action'] = v?.action
                    tempServiceTreatment[i]['bahanAktif'] = {
                        value: v?.bahan_aktif,
                        label: v?.bahan_aktif_description
                    }
                    switch(v?.satuan_bahan_aktif) {
                        case 'gram':
                            tempServiceTreatment[i]['jumlahBahanAktif'] = { value: 'gram', label: 'Gram'}
                            break
                        case 'ml':
                            tempServiceTreatment[i]['jumlahBahanAktif'] = { value: 'ml', label: 'ML'}
                            break
                        case 'buah':
                            tempServiceTreatment[i]['jumlahBahanAktif'] = { value: 'buah', label: 'Buah'}
                            break
                    }
                    tempServiceTreatment[i]['satuanBahanAktif'] =  v?.jumlah_bahan_aktif?.toString()?.replaceAll('.', ',')
                }   
            })

            resultRecomendation?.map((v, i) => {
                let newData = {
                    area: {
                        label: 'Area',
                        mandatory: false,
                        value: v?.area,
                    },
                    date: {
                        label: 'Tanggal',
                        mandatory: false,
                        value: format(new Date(v?.date), 'yyyy-MM-dd'),
                    },
                    detail_recomendation: {
                        label: 'Detail Rekomendasi',
                        mandatory: false,
                        value: v?.detail_recomendation,
                    },
                    status_recomendation: {
                        label: 'Status Rekomendasi',
                        mandatory: false,
                        value: v?.status_recomendation,
                    },
                    verificator: {
                        label: 'Verificator',
                        mandatory: false,
                        value: v?.verificator,
                    },
                    closing_date: {
                        label: 'Tanggal Closing',
                        mandatory: false,
                        value: v?.closing_date ? format(new Date(v?.date), 'yyyy-MM-dd') : '',
                    },
                }
                tempRecommendation?.push(newData)
            })

            const restLength = 10 - tempRecommendation?.length

            if (restLength > 0) {
                [...Array(restLength)]?.map(() => {
                    tempRecommendation?.push({
                        area: {
                            label: 'Area',
                            mandatory: false,
                            value: '',
                        },
                        date: {
                            label: 'Tanggal',
                            mandatory: false,
                            value: '',
                        },
                        detail_recomendation: {
                            label: 'Detail Rekomendasi',
                            mandatory: false,
                            value: '',
                        },
                        status_recomendation: {
                            label: 'Status Rekomendasi',
                            mandatory: false,
                            value: '',
                        },
                        verificator: {
                            label: 'Verificator',
                            mandatory: false,
                            value: '',
                        },
                        closing_date: {
                            label: 'Tanggal Closing',
                            mandatory: false,
                            value: '',
                        },
                    })
                })
            } else {
                tempRecommendation?.push({
                area: {
                    label: 'Area',
                    mandatory: false,
                    value: '',
                },
                date: {
                    label: 'Tanggal',
                    mandatory: false,
                    value: '',
                },
                detail_recomendation: {
                    label: 'Detail Rekomendasi',
                    mandatory: false,
                    value: '',
                },
                status_recomendation: {
                    label: 'Status Rekomendasi',
                    mandatory: false,
                    value: '',
                },
                verificator: {
                    label: 'Verificator',
                    mandatory: false,
                    value: '',
                },
                closing_date: {
                    label: 'Tanggal Closing',
                    mandatory: false,
                    value: '',
                },
            },)
            }

            setArea(() => {
                return [...tempServiceTreatment]
            })
            setForms(() => {
                return [...tempRecommendation]
            })
        }
    })
    const saveResultAll = useMutation(saveResultAllApi, {
        onSuccess: () => {
            modalConflictDisclosure?.onClose()
            modalSuccessDisclosure?.onOpen()
            setValidate(false)
            let task_id = params.get('task_id')
            let token = params.get('token')
            result?.mutate({ task_id: Number(task_id), token })
        },
        onError: (error) => {
            setValidate(false)
            if (error?.data?.recommendation_result?.length > 0) {
                modalSuccessDisclosure?.onClose()
                modalConflictDisclosure?.onOpen()
            } else {
                console.log({ error })
            }
        }
    })

    async function handleSetTabIndex(index) {
        setTabIndex(index)
    }

    async function handleUpdateState() {
        const buttonStateRecommendation = document.getElementById('update-recommendation-state')
        const buttonStateServiceTreatment = document.getElementById('update-service-treatment-state')
        buttonStateRecommendation.click()
        buttonStateServiceTreatment.click()
    }

    const handleSubmit = useCallback( async (override) => {
        try {
            // FORM RECOMMENDATION VALIDATION
            const formRecommendation = document.getElementById('recomendation-form')
            let firstInvalidInputFormRecommendation = null
            for (let i = 0; i < formRecommendation.children.length; i++) {
                if (formRecommendation?.children[i]?.children[0]) {
                    const isInvalid = formRecommendation?.children[i]?.children[0]?.getAttribute('data-invalid')
                    if (isInvalid === '') {
                        firstInvalidInputFormRecommendation = formRecommendation?.children[i]?.children[0]
                        break
                    }
                }
            }
            if (firstInvalidInputFormRecommendation) return handleSetTabIndex(1).then(() => {
                firstInvalidInputFormRecommendation.scrollIntoView({ behavior: 'smooth' })
            })

            // FORM SERVICE TREATMENT VALIDATION
            const formServiceTreatment = document.getElementById('service-treatment-form');
            let firstInvalidInputformServiceTreatment = null;
            for (let i = 0; i < formServiceTreatment.children.length; i++) {
                if (formServiceTreatment?.children[i]?.children[0]) {
                    const isInvalid = formServiceTreatment?.children[i]?.children[0]?.getAttribute('data-invalid')
                    if (isInvalid === '') {
                        firstInvalidInputformServiceTreatment = formServiceTreatment?.children[i]?.children[0]
                        break
                    }
                }
            }
            if (firstInvalidInputformServiceTreatment) return handleSetTabIndex(0).then(() => {
                firstInvalidInputformServiceTreatment.scrollIntoView({ behavior: 'smooth' })
            })

            let task_id = params.get('task_id')
            let token = params.get('token')
            let recomendation = []
            let service_treatment = []

            forms?.map(v => {
                if (!v?.area?.value) return

                if (!v?.status_recomendation?.value) throw 'error'

                if (v?.status_recomendation?.value === 'close') {
                    if (
                        !v?.date?.value ||
                        !v?.detail_recomendation?.value ||
                        !v?.verificator?.value ||
                        !v?.closing_date?.value
                    ) throw 'error'
                }

                if (v?.status_recomendation?.value === 'open') {
                    if (
                        !v?.date?.value ||
                        !v?.detail_recomendation?.value
                    ) throw 'error'
                }

                const newData = {
                    area: v?.area?.value,
                    date: v?.date?.value,
                    detail_recomendation: v?.detail_recomendation?.value,
                    status_recomendation: v?.status_recomendation?.value,
                    verificator: v?.verificator?.value,
                    closing_date: v?.closing_date?.value,
                }
                return recomendation?.push(newData)
            })

            area?.map(v => {
                if (!v?.area) return
                let newData = {}
                if (v?.temuanPest?.value === 'ada') {
                    const selectedSpecies = v?.spesies?.filter(v => v?.selected === true)
                    let listSpecies = ''
                    selectedSpecies?.map((v, i) => {
                        if (i === 0) listSpecies += v.description
                        else listSpecies += ` | ${v.description}`
                    })
                    newData['area'] = v?.area
                    newData['temuan_pest'] = true
                    newData['list_species'] = listSpecies
                    newData['species'] = selectedSpecies?.map(v => {
                        const tangkapan = v?.tangkapan?.map(v => Number(v?.value))
                        if (tangkapan[tangkapan?.length - 1] === 0) tangkapan?.splice(tangkapan?.length - 1, 1)
                        return {
                            id: v?.id,
                            description: v?.description,
                            tangkapan: tangkapan,
                            action: v?.action,
                            bahan_aktif: v?.bahanAktif?.value,
                            bahan_aktif_description: v?.bahanAktif?.label,
                            satuan_bahan_aktif: v?.jumlahBahanAktif?.value??'',
                            jumlah_bahan_aktif: Number(v?.satuanBahanAktif?.replace(',', '.')),
                        }
                    })
                } else {
                    newData['area'] = v?.area
                    newData['temuan_pest'] = false
                    newData['list_species'] = ""
                    newData['action'] = v?.action
                    newData['bahan_aktif'] = v?.bahanAktif?.value
                    newData['bahan_aktif_description'] = v?.bahanAktif?.label
                    newData['satuan_bahan_aktif'] = v?.jumlahBahanAktif?.value??''
                    newData['jumlah_bahan_aktif'] = Number(v?.satuanBahanAktif?.replace(',', '.'))
                }
                return service_treatment?.push(newData)
            })

            if (recomendation?.length === 0 && service_treatment?.length === 0) {
                modalSuccessDisclosure?.onOpen()
                throw 'data service treatment atau rekomendasi 0'
            }

            console.log({ recomendation, service_treatment })

            saveResultAll?.mutate({
                recomendation: JSON.stringify(recomendation),
                service_treatment: JSON.stringify(service_treatment),
                is_checked: override??0,
                task_id: Number(task_id),
                token: token,
                user_name: result?.data?.task?.member_name
            })
        } catch (error) {
            console.log(error)
        }
    }, [forms, area])

    function handleSaveResultAll() {
        handleUpdateState().then(() => handleSubmit())
    }

    useEffect(() => {
        let params = new URL(document.location).searchParams
        let token = params.get('token')
        let task_id = params.get('task_id')
        lookup?.mutate({ task_id: Number(task_id), token })
        // dispatch(lookupSlice.getLookupAction({ token }))
    }, [])

    if (lookup?.isLoading || result?.isLoading) return (
        <Container  
            backgroundColor='white' 
            minHeight='100vh' 
            position='relative'
        >
            <Center marginTop='3rem'>
                <Spinner color='blue.400' />
            </Center>
        </Container>
    )

    if (result?.isSuccess && result?.data?.task?.is_done && (!lookup?.isLoading || !result?.isLoading)) return (
        <Container  
            backgroundColor='white' 
            minHeight='100vh' 
            position='relative'
        >
            <Image
                src={IMG_TASK_DONE}
                width='100%'
            />
            <Stack 
                padding='1rem' 
                boxShadow='rgba(0, 0, 0, 0.12) 0px 1px 6px 0px' 
                backgroundColor='white'
                marginY='1rem'
                borderRadius='4px'
                spacing='0px'
                rounded='12px'
            >
                <Tag 
                    backgroundColor='yellow.100' 
                    color='yellow.600' 
                    fontSize='12px'
                    marginX='auto'
                    rounded='full'
                    marginBottom='1rem'
                >
                    Detail Tugas
                </Tag>
                <Flex>
                    <Text fontSize='12px' width='120px' fontWeight='semibold'>Nama Pelanggan</Text>
                    <Text fontSize='12px' width='10px'>:</Text>
                    <Text 
                        fontSize='12px'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        width='260px'
                    >{result?.data?.task?.customer_name}</Text>
                </Flex>
                <Flex>
                    <Text fontSize='12px' width='120px' fontWeight='semibold'>Nama Teknisi</Text>
                    <Text fontSize='12px' width='10px'>:</Text>
                    <Text 
                        fontSize='12px'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        width='260px'
                    >{result?.data?.task?.member_name}</Text>
                </Flex>
                <Flex>
                    <Text fontSize='12px' width='120px' fontWeight='semibold'>Kode Tugas</Text>
                    <Text fontSize='12px' width='10px'>:</Text>
                    <Text 
                        fontSize='12px'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        width='260px'
                    >{result?.data?.task?.code}</Text>
                </Flex>
                {/* <Flex>
                    <Text fontSize='12px' width='120px' fontWeight='semibold'>Task ID</Text>
                    <Text fontSize='12px' width='10px'>:</Text>
                    <Text 
                        fontSize='12px'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        width='260px'
                    >{params?.get('task_id')}</Text>
                </Flex>
                <Flex>
                    <Text fontSize='12px' width='120px' fontWeight='semibold'>Token</Text>
                    <Text fontSize='12px' width='10px'>:</Text>
                    <Text 
                        fontSize='12px'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        width='260px'
                    >{params?.get('token')}</Text>
                </Flex> */}
            </Stack>
        </Container>
    )

    if (result?.isSuccess && !result?.data?.task?.is_done && (!lookup?.isLoading || !result?.isLoading))return (
        <Container  
            backgroundColor='white' 
            minHeight='100vh' 
            position='relative'
        >
            <Stack marginBottom='1rem' spacing='0px'>
                <Image 
                    src={IMG_LOGO_PCO}
                    width='126px'
                    marginTop='1rem'
                />
                <Stack 
                    padding='1rem' 
                    boxShadow='rgba(0, 0, 0, 0.12) 0px 1px 6px 0px' 
                    backgroundColor='white'
                    marginY='1rem'
                    borderRadius='4px'
                    spacing='0px'
                    rounded='12px'
                >
                    <Flex>
                        <Text fontSize='12px' width='120px' fontWeight='semibold'>Nama Pelanggan</Text>
                        <Text fontSize='12px' width='10px'>:</Text>
                        <Text 
                            fontSize='12px'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            width='260px'
                        >{result?.data?.task?.customer_name}</Text>
                    </Flex>
                    <Flex>
                        <Text fontSize='12px' width='120px' fontWeight='semibold'>Nama Teknisi</Text>
                        <Text fontSize='12px' width='10px'>:</Text>
                        <Text 
                            fontSize='12px'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            width='260px'
                        >{result?.data?.task?.member_name}</Text>
                    </Flex>
                    <Flex>
                        <Text fontSize='12px' width='120px' fontWeight='semibold'>Kode Tugas</Text>
                        <Text fontSize='12px' width='10px'>:</Text>
                        <Text 
                            fontSize='12px'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            width='260px'
                        >{result?.data?.task?.code}</Text>
                    </Flex>
                    {/* <Flex>
                        <Text fontSize='12px' width='120px' fontWeight='semibold'>Task ID</Text>
                        <Text fontSize='12px' width='10px'>:</Text>
                        <Text 
                            fontSize='12px'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            width='260px'
                        >{params?.get('task_id')}</Text>
                    </Flex>
                    <Flex>
                        <Text fontSize='12px' width='120px' fontWeight='semibold'>Token</Text>
                        <Text fontSize='12px' width='10px'>:</Text>
                        <Text 
                            fontSize='12px'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            width='260px'
                        >{params?.get('token')}</Text>
                    </Flex> */}
                </Stack>
                <Button
                    leftIcon={<Icon as={BiRefresh} />}
                    marginLeft='auto'
                    colorScheme='yellow'
                    fontWeight='normal'
                    size='sm'
                    onClick={() => {
                        let task_id = params.get('task_id')
                        let token = params.get('token')
                        result?.mutate({ task_id: Number(task_id), token })
                    }}
                >
                    Refresh
                </Button>
            </Stack>
            <Tabs 
                paddingBottom='16px' 
                backgroundColor='white'
                onChange={(index) => setTabIndex(index)}
                index={tabIndex}
                colorScheme='yellow'
            >
                <TabList position='sticky' top='0px' backgroundColor='white' zIndex='999'>
                    <Tab width='50%'>
                        Service Treatment
                        <Center 
                            width='16px' 
                            height='16px' 
                            rounded='full' 
                            backgroundColor='yellow.100'
                            marginLeft='7px'
                            fontSize='11px'
                            color='yellow.600'
                            fontWeight='400'
                        >
                            {area?.filter(x => x?.area !== '')?.length}
                        </Center>
                    </Tab>
                    <Tab width='50%'>
                        Rekomendasi
                        <Center 
                            width='16px' 
                            height='16px' 
                            rounded='full' 
                            backgroundColor='yellow.100'
                            marginLeft='7px'
                            fontSize='11px'
                            color='yellow.600'
                            fontWeight='400'
                        >
                            {forms?.filter(x => x?.area?.value !== '')?.length}
                        </Center>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {
                            result?.isLoading || lookup?.isLoading
                                ?   <Center>
                                        <Spinner margin='auto' />
                                    </Center>
                                :   <ServiceTreatmentTab 
                                        area={area} 
                                        setArea={setArea} 
                                        lookup={lookup} 
                                        handleSubmit={handleSaveResultAll}
                                        validate={validate}
                                        setValidate={setValidate}
                                        saveResultAll={saveResultAll}
                                        tabIndex={tabIndex}
                                    />
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            result?.isLoading || lookup?.isLoading
                                ?   <Center>
                                        <Spinner margin='auto' />
                                    </Center>
                                :   <RecomendationTab 
                                        forms={forms} 
                                        setForms={setForms} 
                                        handleSubmit={handleSaveResultAll}
                                        validate={validate}
                                        setValidate={setValidate}
                                        saveResultAll={saveResultAll}
                                        tabIndex={tabIndex}
                                        result={result}
                                    />
                        }
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <SuccessModal 
                isOpen={modalSuccessDisclosure?.isOpen} 
                onClose={modalSuccessDisclosure?.onClose} 
            />

            <ConflictModal 
                isOpen={modalConflictDisclosure?.isOpen} 
                onClose={modalConflictDisclosure?.onClose} 
                errors={saveResultAll?.error}
                isLoadingOverride={saveResultAll?.isLoading}
                override={() => {
                    handleSubmit(1)
                }}
            />
        </Container>
    )
}

export default App