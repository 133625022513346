import React, { 
    Fragment, 
    memo, 
    useEffect, 
    useState, 
} from 'react'
import useInterval from 'use-interval'
import Saving from './Saving'
import Saved from './Saved'
import { useMutation } from 'react-query'
import { saveRecommendationApi } from '../api/request'
import { 
    Button,
    Center,
    Divider,
    Flex, 
    FormControl, 
    FormErrorMessage,
    FormLabel, 
    Input,
    Select,
    Text,
    Textarea,
} from '@chakra-ui/react'

const RecomendationTab = ({ forms, setForms, handleSubmit, validate, setValidate, saveResultAll, tabIndex, result }) => {
    const [localDataRecommendation, setLocalDataRecommendation] = useState(forms)
    const [lastSync, setLastSync] = useState(null)

    const saveRecommendation = useMutation(saveRecommendationApi, {
        onSuccess: () => {
            setLastSync(new Date().getTime())
        }
    })

    useEffect(() => {
        console.log({forms})
        setLocalDataRecommendation(() => {
            return [...forms]
        })
    }, [forms])

    function handleChangeForm(index, name, value) {
        let tempForms = localDataRecommendation
        
        try {
            switch(name) {
                case 'area':
                    if (value !== '' || value === null) {
                        tempForms[index][name]['value'] = value?.toUpperCase()
                    } else {
                        tempForms[index][name]['value'] = ''
                        tempForms[index]['date']['value'] = ''
                        tempForms[index]['detail_recomendation']['value'] = ''
                        tempForms[index]['status_recomendation']['value'] = ''
                        tempForms[index]['verificator']['value'] = ''
                        tempForms[index]['closing_date']['value'] = ''
                    }
                    break
                case 'date':
                    tempForms[index][name]['value'] = value
                    break
                case 'detail_recomendation':
                    tempForms[index][name]['value'] = value
                    break
                case 'status_recomendation':
                    tempForms[index][name]['value'] = value
                    tempForms[index]['verificator']['value'] = ''
                    tempForms[index]['closing_date']['value'] = ''
                    break
                case 'verificator':
                    tempForms[index][name]['value'] = value
                    break
                case 'closing_date':
                    tempForms[index][name]['value'] = value
                    break
                default:
                    break
            }
        } catch (error) {
            
        }

        setLocalDataRecommendation(() => {
            return [...tempForms]
        })
    }

    function handleDuplicateArea(data) {
        if (data?.area?.value === '') return
        const duplicates = localDataRecommendation?.filter(x => x?.area?.value === data?.area?.value)
        if (duplicates?.length >= 2) return true
        else return false
    }

    async function setValidateTrue() {
        setValidate(true)
    }

    useInterval(() => {
        try {
            if (tabIndex !== 1) throw 'recommendation tab inactive'
            let params = new URL(document.location).searchParams
            let task_id = params.get('task_id')
            let token = params.get('token')
            let recomendation = []
            localDataRecommendation?.map(v => {
                if (!v?.area?.value) return

                if (!v?.status_recomendation?.value) throw 'error'

                if (v?.status_recomendation?.value === 'close') {
                    if (
                        !v?.date?.value ||
                        !v?.detail_recomendation?.value ||
                        !v?.verificator?.value ||
                        !v?.closing_date?.value
                    ) throw 'error'
                }

                if (v?.status_recomendation?.value === 'open') {
                    if (
                        !v?.date?.value ||
                        !v?.detail_recomendation?.value
                    ) throw 'error'
                }

                const newData = {
                    area: v?.area?.value,
                    date: v?.date?.value,
                    detail_recomendation: v?.detail_recomendation?.value,
                    status_recomendation: v?.status_recomendation?.value,
                    verificator: v?.verificator?.value,
                    closing_date: v?.closing_date?.value,
                }
                return recomendation?.push(newData)
            })
            if (recomendation?.length === 0) throw 'recomendasi 0'
            saveRecommendation?.mutate({
                recomendation: JSON.stringify(recomendation),
                is_checked: 0,
                task_id: Number(task_id),
                token,
                user_name: result?.data?.task?.member_name
            })
        } catch (error) {
            console.log(error)
        }
    }, 120000)

    console.log('recommendation rendered')

    return (
        <Flex 
            direction='column'
            gap='16px' 
            position='relative'
            id='recomendation-form'
        >
            <Flex 
                alignItems='center' 
                justifyContent='end' 
                gap='1rem'
            >
                { saveRecommendation?.isLoading && <Saving /> }
                { !saveRecommendation?.isLoading && lastSync && <Saved lastSync={lastSync} /> }
            </Flex>

            <Flex alignItems='center' gap='7px'>
                <Text fontSize='12px'>Total Area Diisi</Text>
                <Center 
                    width='16px' 
                    height='16px' 
                    rounded='full' 
                    backgroundColor='yellow.100'
                    marginLeft='7px'
                    fontSize='11px'
                    color='yellow.600'
                    fontWeight='400'
                >
                    <Text>
                        {localDataRecommendation?.filter(x => x?.area?.value !== '')?.length}
                    </Text>
                </Center>
            </Flex>
            
            {
                localDataRecommendation?.map((v, i) => {
                    return (
                        <Fragment key={i}>
                            <FormControl isInvalid={validate && handleDuplicateArea(v)}>
                                <FormLabel fontSize='12px'>Area {i + 1} {v?.area?.value === '' ? '' : `(${v?.area?.value})`}</FormLabel>
                                <Input
                                    boxShadow='none'
                                    _focusVisible={{
                                        borderColor: 'yellow.400',
                                        boxShadow: '0 0 0 1px #ecc94b' 
                                    }}
                                    name='area' 
                                    type='text' 
                                    fontSize='12px' 
                                    value={v?.area?.value}
                                    onChange={(e) => handleChangeForm(i, e?.target?.name, e?.target?.value)}
                                />
                                <FormErrorMessage fontSize='12px'>Nama area sudah terdaftar</FormErrorMessage>
                            </FormControl>

                            {
                                v?.area?.value !== '' && v?.area?.value !== null
                                    ?   <>
                                            <FormControl isInvalid={validate && v?.area?.value && !v?.date?.value}>
                                                <FormLabel fontSize='12px'>Tanggal {i + 1} {v?.area?.value === '' ? '' : `(${v?.area?.value})`}</FormLabel>
                                                <Input
                                                    _focusVisible={{
                                                        borderColor: 'yellow.400',
                                                        boxShadow: '0 0 0 1px #ecc94b' 
                                                    }}
                                                    name='date' 
                                                    type='date' 
                                                    fontSize='12px' 
                                                    value={v?.date?.value}
                                                    onChange={(e) => handleChangeForm(i, e?.target?.name, e?.target?.value)}
                                                />
                                                <FormErrorMessage fontSize='12px'>Wajib Diisi</FormErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={validate && v?.area?.value && !v?.detail_recomendation?.value}>
                                                <FormLabel fontSize='12px'>Detail Rekomendasi {i + 1} {v?.area?.value === '' ? '' : `(${v?.area?.value})`}</FormLabel>
                                                <Textarea
                                                    _focusVisible={{
                                                        borderColor: 'yellow.400',
                                                        boxShadow: '0 0 0 1px #ecc94b' 
                                                    }}
                                                    fontSize='12px' 
                                                    resize='none'
                                                    name='detail_recomendation' 
                                                    type='text' 
                                                    value={v?.detail_recomendation?.value}
                                                    onChange={(e) => handleChangeForm(i, e?.target?.name, e?.target?.value)}
                                                />
                                                <FormErrorMessage fontSize='12px'>Wajib Diisi</FormErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={validate && v?.area?.value && !v?.status_recomendation?.value}>
                                                <FormLabel fontSize='12px'>Status Rekomendasi {i + 1} {v?.area?.value === '' ? '' : `(${v?.area?.value})`}</FormLabel>
                                                <Select
                                                    _focusVisible={{
                                                        borderColor: 'yellow.400',
                                                        boxShadow: '0 0 0 1px #ecc94b' 
                                                    }}
                                                    name='status_recomendation' 
                                                    placeholder='Select option' 
                                                    value={v?.status_recomendation?.value}
                                                    fontSize='12px'
                                                    onChange={(e) => handleChangeForm(i, e?.target?.name, e?.target?.value)}
                                                >
                                                    <option value='close'>Close</option>
                                                    <option value='open'>Open</option>
                                                </Select>
                                                <FormErrorMessage fontSize='12px'>Wajib Diisi</FormErrorMessage>
                                            </FormControl>

                                            {
                                                v?.status_recomendation?.value === 'close'  
                                                    ?   <>
                                                            <FormControl isInvalid={validate && v?.area?.value && v?.status_recomendation?.value === 'close' && !v?.closing_date?.value}>
                                                                <FormLabel fontSize='12px'>Tanggal Closing {i + 1} {v?.area?.value === '' ? '' : `(${v?.area?.value})`}</FormLabel>
                                                                <Input
                                                                    _focusVisible={{
                                                                        borderColor: 'yellow.400',
                                                                        boxShadow: '0 0 0 1px #ecc94b' 
                                                                    }}
                                                                    name='closing_date' 
                                                                    type='date' 
                                                                    fontSize='12px' 
                                                                    value={v?.closing_date?.value}
                                                                    onChange={(e) => handleChangeForm(i, e?.target?.name, e?.target?.value)}
                                                                />
                                                                <FormErrorMessage fontSize='12px'>Wajib Diisi</FormErrorMessage>
                                                            </FormControl>

                                                            <FormControl isInvalid={validate && v?.area?.value && v?.status_recomendation?.value === 'close' && !v?.verificator?.value}>
                                                                <FormLabel fontSize='12px'>Verifikator {i + 1} {v?.area?.value === '' ? '' : `(${v?.area?.value})`}</FormLabel>
                                                                <Input
                                                                    _focusVisible={{
                                                                        borderColor: 'yellow.400',
                                                                        boxShadow: '0 0 0 1px #ecc94b' 
                                                                    }}
                                                                    name='verificator' 
                                                                    type='text' 
                                                                    fontSize='12px' 
                                                                    value={v?.verificator?.value}
                                                                    onChange={(e) => handleChangeForm(i, e?.target?.name, e?.target?.value)}
                                                                />
                                                                <FormErrorMessage fontSize='12px'>Wajib Diisi</FormErrorMessage>
                                                            </FormControl>
                                                        </>
                                                    :   null
                                            }
                                        </> 
                                    :   null
                            }

                            <Divider marginY='1rem' />
                        </Fragment>
                    )
                })
            }
            
            {
                localDataRecommendation?.length <= 49 &&
                <Button 
                    width='100%' 
                    fontSize='12px' 
                    colorScheme='yellow' 
                    variant='outline'
                    onClick={() => {
                        if (localDataRecommendation?.length >= 50) return
                        setLocalDataRecommendation((prev) => {
                            return [
                                ...prev,
                                {
                                    area: {
                                        label: 'Area',
                                        mandatory: false,
                                        value: '',
                                    },
                                    date: {
                                        label: 'Tanggal',
                                        mandatory: false,
                                        value: '',
                                    },
                                    detail_recomendation: {
                                        label: 'Detail Rekomendasi',
                                        mandatory: false,
                                        value: '',
                                    },
                                    status_recomendation: {
                                        label: 'Status Rekomendasi',
                                        mandatory: false,
                                        value: '',
                                    },
                                    verificator: {
                                        label: 'Verificator',
                                        mandatory: false,
                                        value: '',
                                    },
                                    closing_date: {
                                        label: 'Tanggal Closing',
                                        mandatory: false,
                                        value: '',
                                    },
                                },
                            ]
                        })
                    }}
                >
                    Tambah Area
                </Button>
            }
            
            <Button
                id='update-recommendation-state'
                visibility='hidden'
                onClick={() => {
                    console.log('update-recommendation-state clicked')
                    setForms(() => {
                        return [...localDataRecommendation]
                    })
                }}
            >
                Update State Rekomendasi
            </Button>

            <Flex
                position='sticky'
                bottom='0'
                paddingY='8px'
                marginY='-8px'
                backgroundColor='white'
            >
                <Button
                    width='100%' 
                    fontSize='12px' 
                    colorScheme='yellow'
                    isLoading={saveRecommendation?.isLoading || saveResultAll?.isLoading}
                    onClick={() => {
                        setValidateTrue().then(() => handleSubmit())
                    }}
                >Simpan</Button>
            </Flex>
        </Flex>
    )
}

export default memo(RecomendationTab)