import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const queryClient = new QueryClient({
  defaultOptions: {
      queries: {
          refetchOnWindowFocus: false,
      },
  },
})

const theme = extendTheme({
  components: {
    Input: {
      baseStyle: {
        _focus: {
          // Customize focus styles here
          borderColor: "red.500", // Change the border color on focus
          boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0)", // Add a box shadow on focus
        },
        _focusVisible: {
          // Customize focus styles here
          borderColor: "red.500", // Change the border color on focus
          boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0)", // Add a box shadow on focus
        },
        _focusWithin: {
          // Customize focus styles here
          borderColor: "red.500", // Change the border color on focus
          boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0)", // Add a box shadow on focus
        },
      },
    },
  },
});

console.log(theme)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
