import axios, { 
    AxiosError, 
    AxiosInstance, 
    AxiosResponse, 
    CreateAxiosDefaults, 
} from 'axios'

const http = () => {
    const config = { baseURL: process.env.REACT_APP_BASE_URL }

    const axiosInstance = axios.create(config)

    axiosInstance.interceptors.request.use(
        function (config) {
            // Do something before request is sent
            return config
        }, 
        function (error) {
            // Do something with request error
            return Promise.reject(error)
        }
    )

    axiosInstance.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response.data
        }, 
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error?.response)
        }
    )

    return axiosInstance
}

export default http


