import React, { memo } from 'react'
import { 
    Button,
    Flex,
    Image,
    Modal, 
    ModalBody, 
    ModalContent, 
    ModalOverlay, 
    Text, 
} from '@chakra-ui/react'
import { IC_DATA_SAVED } from '../assets'

const SuccessModal = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent margin='22px' padding='0px'>
                <ModalBody 
                    padding='24px' 
                    as={Flex} 
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    gap='24px'
                >
                    <Text
                        fontSize='16px'
                        fontWeight='500'
                    >Data Tersimpan</Text>
                    <Flex justifyContent='space-between' alignItems='center' gap='24px'>
                        <Image 
                            src={IC_DATA_SAVED} 
                            width='60px' 
                            height='60px' 
                        />
                        <Text
                            fontSize='12px'
                            fontWeight='400'
                            color='#76777A'
                            textAlign='justify'
                        >Anda dapat melanjutkan pekerjaan lainnya di form ini atau klik tombol kembali untuk mengirimkan tugas ke admin.</Text>
                    </Flex>
                    <Button 
                        width='204px' 
                        fontSize='12px' 
                        colorScheme='yellow'
                        onClick={onClose}
                    >OK</Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default memo(SuccessModal)