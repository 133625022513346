import React, { memo, useEffect, useState } from 'react'
import { 
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    Modal, 
    ModalBody, 
    ModalContent, 
    ModalFooter, 
    ModalOverlay, 
    Stack, 
    Text, 
} from '@chakra-ui/react'

const ConflictModal = ({ isOpen, onClose, errors, override, isLoadingOverride }) => {
    const [conflicts, setConflicts] = useState(errors?.data?.recommendation_result)

    useEffect(() => {
        if (isOpen) {
            setConflicts(errors?.data?.recommendation_result)
        }
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent margin='22px' padding='0px'>
                <ModalBody 
                    padding='24px' 
                    as={Flex} 
                    direction='column'
                    gap='24px'
                >
                    <Text
                        fontSize='16px'
                        fontWeight='bold'
                        textAlign='center'
                    >Konfirmasi Perubahan Data</Text>
                    <Alert status='info' fontSize='xs'>
                        <AlertIcon />
                        terdapat perubahan data pada detail area atau terdapat nama area yang telah terdaftar di server, perhatikan detail sebelum melakukan perbarui data.
                    </Alert>
                    <Box marginBottom='-8px'>
                        <Flex>
                            <Text fontSize='xs' fontWeight='bold'>Total Perubahan Data Area: </Text>
                            <Text fontSize='xs' marginLeft='4px'>{conflicts?.length}</Text>
                        </Flex>
                        <Flex>
                            <Text fontSize='xs' fontWeight='bold'>Area ini dikerjakan oleh: </Text>
                            <Text fontSize='xs' marginLeft='4px'>{errors?.data?.user_name}</Text>
                        </Flex>
                    </Box>
                    <Stack width='100%' maxHeight='50vh' overflow='auto' padding='8px' margin='-8px'>
                        {
                            conflicts?.map((v, i) => {
                                return (
                                    <Flex
                                        direction='column' 
                                        key={i} 
                                        boxShadow='rgba(0, 0, 0, 0.12) 0px 1px 6px 0px'
                                        width='100%'
                                        flex='1'
                                        padding='8px'
                                        gap='8px'
                                    >
                                        <Flex>
                                            <Text width='120px' fontSize='xs'>Area</Text>
                                            <Text marginRight='4px' fontSize='xs'>:</Text>
                                            <Text fontSize='xs'>{v?.area}</Text>
                                        </Flex>
                                        <Flex>
                                            <Text width='120px' fontSize='xs'>Tanggal</Text>
                                            <Text marginRight='4px' fontSize='xs'>:</Text>
                                            <Text fontSize='xs'>{v?.date}</Text>
                                        </Flex>
                                        <Flex>
                                            <Text width='120px' fontSize='xs'>Detail Rekomendasi</Text>
                                            <Text marginRight='4px' fontSize='xs'>:</Text>
                                            <Text fontSize='xs'>{v?.detail_recomendation}</Text>
                                        </Flex>
                                        <Flex>
                                            <Text width='120px' fontSize='xs'>Status Rekomendasi</Text>
                                            <Text marginRight='4px' fontSize='xs'>:</Text>
                                            <Text fontSize='xs'>{v?.status_recomendation}</Text>
                                        </Flex>
                                        <Flex>
                                            <Text width='120px' fontSize='xs'>Tanggal Closing</Text>
                                            <Text marginRight='4px' fontSize='xs'>:</Text>
                                            <Text fontSize='xs'>{v?.closing_date}</Text>
                                        </Flex>
                                        <Flex>
                                            <Text width='120px' fontSize='xs'>Verificator</Text>
                                            <Text marginRight='4px' fontSize='xs'>:</Text>
                                            <Text fontSize='xs'>{v?.verificator}</Text>
                                        </Flex>
                                    </Flex>
                                )
                            })
                        }
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        colorScheme='yellow' 
                        variant='outline'
                        fontWeight='normal'
                        fontSize='sm'
                        onClick={onClose}
                    >Batal</Button>
                    <Button 
                        colorScheme='yellow' 
                        marginLeft='0.5rem'
                        fontWeight='normal'
                        fontSize='sm'
                        onClick={override}
                        isLoading={isLoadingOverride}
                    >Perbaharui Data</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default memo(ConflictModal)