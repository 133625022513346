import { 
    Fragment, 
    memo, 
    useCallback, 
    useState, 
} from 'react'
import Saving from './Saving'
import Saved from './Saved'
import CurrencyInput from 'react-currency-input-field'
import useInterval from 'use-interval'
import { useMutation } from 'react-query'
import { saveServiceTreatmentApi } from '../api/request'
import { Select } from 'chakra-react-select'
import { 
    Button,
    Center,
    Divider, 
    Flex, 
    FormControl, 
    FormErrorMessage, 
    FormLabel, 
    Input,
    Text,
} from '@chakra-ui/react'

function ServiceTreatmentTab({ area, setArea, lookup, handleSubmit, validate, setValidate, saveResultAll, tabIndex }) {
    const [localDataServiceTreatment, setLocalDataServiceTreatment] = useState(area)
    const [lastSync, setLastSync] = useState(null)

    const saveServiceTreatment = useMutation(saveServiceTreatmentApi, {
        onSuccess: () => {
            setLastSync(new Date().getTime())
        }
    })

    const handleChangeAreaAndTemuanPest = useCallback((areaIndex, fieldName, value, localData) => {
        let tempArea = [...localData]
        switch(fieldName) {
            case 'area':
                tempArea[areaIndex][fieldName] = value?.toUpperCase()
                break
            default:
                tempArea[areaIndex][fieldName] = value?.value === null ? null : value
        }
        setLocalDataServiceTreatment(() => {
            return [...tempArea]
        })
    }, [])

    const handleChangeTangkapan = useCallback((areaIndex, speciesIndex, tangkapanIndex, value, localData) => {
        let tempArea = [...localData]        
        const re = /^[0-9\b]+$/
        const newValue = value?.replaceAll('.', '')?.replaceAll(',', '')

        console.log(tempArea[areaIndex].spesies[speciesIndex].tangkapanMax)
        tempArea[areaIndex].spesies[speciesIndex].tangkapan[tangkapanIndex].value = re.test(newValue)
            ?   newValue
            :   newValue === ''
                ?   newValue
                :   tempArea[areaIndex].spesies[speciesIndex].tangkapan[tangkapanIndex].value

        if (
            tempArea[areaIndex].spesies[speciesIndex].tangkapan.length - 1 === tangkapanIndex && 
            value >= 0 &&
            tempArea[areaIndex].spesies[speciesIndex].tangkapan.length < tempArea[areaIndex].spesies[speciesIndex].tangkapanMax
        ) {
            tempArea[areaIndex].spesies[speciesIndex].tangkapan.push({ value: '' })
        } else if (tempArea[areaIndex].spesies[speciesIndex].tangkapan.length > 1 && value === '') {
            // tempArea[areaIndex].spesies[speciesIndex].tangkapan.splice(tangkapanIndex, 1)
            tempArea[areaIndex].spesies[speciesIndex].tangkapan[tangkapanIndex].value = '0'
        }

        setLocalDataServiceTreatment(() => {
            return [...tempArea]
        })
    }, [])

    const handleToggleSpesies = useCallback((areaIndex, spesiesIndex, localData) => {
        let tempArea = [...localData]
        tempArea[areaIndex].spesies[spesiesIndex].selected = !tempArea[areaIndex].spesies[spesiesIndex].selected
        if (tempArea[areaIndex].spesies[spesiesIndex].selected === true) {
            tempArea[areaIndex].spesies[spesiesIndex].tangkapan = [{ value: '' }]
        } else {
            tempArea[areaIndex].spesies[spesiesIndex].tangkapan = []
        }
        setLocalDataServiceTreatment(() => {
            return [...tempArea]
        })
    }, [])

    const handleChangeInfo = useCallback((areaIndex, spesiesIndex, name, value, localData) => {
        let tempArea = [...localData]
        const re = /^[0-9\b]+$/

        if (name === 'satuanBahanAktif2') {
            // const newValue = value?.replaceAll('.', '')?.replaceAll(',', '')
            let newValue = value?.replaceAll('.', '')
            newValue = newValue?.replaceAll(',', '.')
            tempArea[areaIndex].spesies[spesiesIndex][name] = re.test(newValue) 
                ?   newValue
                :   newValue === ''
                    ?   newValue
                    :   tempArea[areaIndex].spesies[spesiesIndex][name]
        } else {
            tempArea[areaIndex].spesies[spesiesIndex][name] = value?.value === null ? null : value
        }

        setLocalDataServiceTreatment(() => {
            return [...tempArea]
        })
    }, [])

    const handleChangeNoPestField = useCallback((areaIndex, fieldName, value, localData) => {
        let tempArea = [...localData]
        tempArea[areaIndex][fieldName] = value?.value === null ? null : value
        setLocalDataServiceTreatment(() => {
            return [...tempArea]
        })
    }, [])

    function handleDuplicateArea(data) {
        if (data?.area === '') return
        const duplicates = area?.filter(x => x?.area === data?.area)
        if (duplicates?.length >= 2) return true
        else return false
    }

    async function handleSetValidateTrue() {
        setValidate(true)
    }

    useInterval(() => {
        try {
            if (tabIndex !== 0) throw 'service treatment tab inactive'
            let params = new URL(document.location).searchParams
            let task_id = params.get('task_id')
            let token = params.get('token')
            let service_treatment = []

            area?.map(v => {
                if (!v?.area) return
                if (!v?.temuanPest) throw 'temuan pest belum dipilih'
                let newData = {}
                if (v?.temuanPest?.value === 'ada') {
                    const selectedSpecies = v?.spesies?.filter(v => v?.selected === true)
                    let listSpecies = ''
                    selectedSpecies?.map((v, i) => {
                        if (i === 0) listSpecies += v.description
                        else listSpecies += ` | ${v.description}`
                    })
                    newData['area'] = v?.area
                    newData['temuan_pest'] = true
                    newData['list_species'] = listSpecies
                    newData['species'] = selectedSpecies?.map(v => {
                        const tangkapan = v?.tangkapan?.map(v => Number(v?.value))
                        if (tangkapan[tangkapan?.length - 1] === 0) tangkapan?.splice(tangkapan?.length - 1, 1)
                        return {
                            id: v?.id,
                            description: v?.description,
                            tangkapan: tangkapan,
                            action: v?.action,
                            bahan_aktif: v?.bahanAktif?.value,
                            bahan_aktif_description: v?.bahanAktif?.label,
                            satuan_bahan_aktif: v?.jumlahBahanAktif?.value,
                            jumlah_bahan_aktif: Number(v?.satuanBahanAktif?.replace(',', '.')),
                        }
                    })
                } else {
                    newData['area'] = v?.area
                    newData['temuan_pest'] = false
                    newData['list_species'] = ""
                    newData['action'] = v?.action
                    newData['bahan_aktif'] = v?.bahanAktif?.value
                    newData['bahan_aktif_description'] = v?.bahanAktif?.label
                    newData['satuan_bahan_aktif'] = v?.jumlahBahanAktif?.value
                    newData['jumlah_bahan_aktif'] = Number(v?.satuanBahanAktif?.replace(',', '.'))
                }
                return service_treatment?.push(newData)
            })
            if (service_treatment?.length === 0) throw 'service_treatment 0'
            saveServiceTreatment?.mutate({ 
                task_id: Number(task_id), 
                service_treatment: JSON.stringify(service_treatment),
                token,
            })
        } catch (error) {
            console.log(error)
        }
    }, 120000)

    console.log('service treatment rendered')

    return (
        <Flex 
            direction='column'
            gap='16px' 
            position='relative'
            id='service-treatment-form'
        >
            <Flex
                alignItems='center'
                justifyContent='end'
                gap='1rem'
            >
                { saveServiceTreatment?.isLoading && <Saving /> }
                { !saveServiceTreatment?.isLoading && lastSync && <Saved lastSync={lastSync} /> }
            </Flex>

            <Flex alignItems='center' gap='7px'>
                <Text fontSize='12px'>Total Area Diisi</Text>
                <Center 
                    width='16px' 
                    height='16px' 
                    rounded='full' 
                    backgroundColor='yellow.100'
                    marginLeft='7px'
                    fontSize='11px'
                    color='yellow.600'
                    fontWeight='400'
                >
                    <Text>
                        {localDataServiceTreatment?.filter(x => x?.area !== '')?.length}
                    </Text>
                </Center>
            </Flex>

            {
                localDataServiceTreatment?.map((areaValue, areaIndex) => {
                    return (
                        <Fragment key={areaIndex}>
                            <FormControl 
                                marginTop={areaIndex !== 0 ? '3rem' : '0px'}
                                isInvalid={validate && areaValue?.area && handleDuplicateArea(areaValue)}
                            >
                                <FormLabel fontSize='12px'>Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                <Input 
                                    _focusVisible={{
                                        borderColor: 'yellow.400',
                                        boxShadow: '0 0 0 1px #ecc94b' 
                                    }}
                                    type='text' 
                                    fontSize='12px' 
                                    value={areaValue?.area}
                                    onChange={e => handleChangeAreaAndTemuanPest(areaIndex, 'area', e?.target?.value, localDataServiceTreatment)}
                                />
                                <FormErrorMessage fontSize='12px'>Nama area sudah terdaftar</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={validate && areaValue?.area && !areaValue?.temuanPest}>
                                <FormLabel fontSize='12px'>Temuan Pest Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                <Select
                                    useBasicStyles
                                    options={[
                                        {
                                            label: "Select...",
                                            value: null,
                                        },
                                        {
                                            label: "Ada",
                                            value: "ada",
                                        },
                                        {
                                            label: "Tidak",
                                            value: "tidak",
                                        },
                                    ]}
                                    value={areaValue?.temuanPest}
                                    chakraStyles={{
                                        input: (provided) => ({
                                            ...provided,
                                            fontsize: '12px'
                                        }),
                                        control: (provided, state) => {
                                            return ({
                                                ...provided,
                                                _focusVisible: {
                                                    boxShadow: '0 0 0 1px #ecc94b'
                                                }
                                            })
                                        },
                                    }}
                                    selectedOptionColorScheme='yellow'
                                    onChange={e => handleChangeAreaAndTemuanPest(areaIndex, 'temuanPest', e, localDataServiceTreatment)}
                                />
                                <FormErrorMessage fontSize='12px'>Wajib Diisi</FormErrorMessage>
                            </FormControl>

                            {
                                areaValue?.temuanPest?.value === 'ada' &&
                                <Fragment>
                                    <FormControl isInvalid={validate && areaValue?.area && areaValue?.spesies?.filter(v => v?.selected === true)?.length <= 0}>
                                        <FormLabel fontSize='12px'>Spesies Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                        <FormErrorMessage fontSize='12px' marginBottom='12px'>Wajib diisi</FormErrorMessage>
                                        <Flex gap='4px' flexWrap='wrap'>
                                            {areaValue?.spesies?.map((v, i) => {
                                                return (
                                                    <Center 
                                                        key={i} 
                                                        padding='4px 8px' 
                                                        border={v?.selected ? '1px solid #ecc94b' : '1px solid #ecc94b'} 
                                                        rounded='full' 
                                                        cursor='pointer'
                                                        backgroundColor={v?.selected ? 'yellow.400' : 'transparent'}
                                                        onClick={() => handleToggleSpesies(areaIndex, i, localDataServiceTreatment)}
                                                    >
                                                        <Text fontSize='12px' color={v?.selected ? 'white' : '#ecc94b'}>{v?.description}</Text>
                                                    </Center>
                                                )
                                            })}
                                        </Flex>
                                    </FormControl>

                                    {
                                        areaValue?.spesies?.map((v, i) => {
                                            if (v?.selected === true) {
                                                return (
                                                    <Fragment key={i}>
                                                        {v?.tangkapan?.map((nv, ni) => {
                                                            return (
                                                                <FormControl 
                                                                    key={ni}
                                                                    isInvalid={validate && areaValue?.temuanPest?.value === 'ada' && v?.tangkapan?.length <= 1}
                                                                >
                                                                    <FormLabel fontSize='12px'>Jumlah Tangkapan ({v?.description}) {ni + 1} Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                                                    <Input 
                                                                        _focusVisible={{
                                                                            borderColor: 'yellow.400',
                                                                            boxShadow: '0 0 0 1px #ecc94b' 
                                                                        }}
                                                                        type='text' 
                                                                        fontSize='12px' 
                                                                        value={nv?.value ? Number(nv?.value)?.toLocaleString('id') : nv?.value} 
                                                                        onChange={e => handleChangeTangkapan(areaIndex, i, ni, e?.target?.value, localDataServiceTreatment)}
                                                                    />
                                                                </FormControl>
                                                            )
                                                        })}

                                                        <FormControl isInvalid={validate && areaValue?.temuanPest?.value === 'ada' && !v?.action}>
                                                            <FormLabel fontSize='12px'>Action ({v?.description}) Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                                            <Input 
                                                                _focusVisible={{
                                                                    borderColor: 'yellow.400',
                                                                    boxShadow: '0 0 0 1px #ecc94b' 
                                                                }}
                                                                type='text' 
                                                                fontSize='12px' 
                                                                value={v?.action} 
                                                                onChange={e => handleChangeInfo(areaIndex, i, 'action', e?.target?.value, localDataServiceTreatment)}
                                                            />
                                                        </FormControl>

                                                        <FormControl isInvalid={validate && areaValue?.temuanPest?.value === 'ada' && !v?.bahanAktif}>
                                                            <FormLabel fontSize='12px'>Bahan Aktif ({v?.description}) Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                                            <Select
                                                                useBasicStyles
                                                                chakraStyles={{
                                                                    input: (provided) => ({
                                                                        ...provided,
                                                                        fontsize: '12px'
                                                                    }),
                                                                    control: (provided, state) => {
                                                                        return ({
                                                                            ...provided,
                                                                            _focusVisible: {
                                                                                boxShadow: '0 0 0 1px #ecc94b'
                                                                            }
                                                                        })
                                                                    },
                                                                }}
                                                                selectedOptionColorScheme='yellow'
                                                                options={[
                                                                    {
                                                                        label: "Select...",
                                                                        value: null,
                                                                    },
                                                                    ...lookup?.data?.bahan_aktif?.lookup_value?.map(v => {
                                                                        return {
                                                                            label: v?.description,
                                                                            value: v?.id,
                                                                        }
                                                                    })
                                                                ]}
                                                                value={v?.bahanAktif}
                                                                onChange={e => {
                                                                    console.log(e)
                                                                    handleChangeInfo(areaIndex, i, 'bahanAktif', e, localDataServiceTreatment)
                                                                }}
                                                            />
                                                        </FormControl>

                                                        {
                                                            v?.bahanAktif && v?.bahanAktif?.label !== 'Tidak Ada' &&
                                                            <FormControl isInvalid={validate && areaValue?.temuanPest?.value === 'ada' && !v?.jumlahBahanAktif}>
                                                                <FormLabel fontSize='12px'>Jumlah Pestisida / Bahan Kimia / Device Yang Digunakan ({v?.description}) Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                                                <Select
                                                                    useBasicStyles
                                                                    chakraStyles={{
                                                                        input: (provided) => ({
                                                                            ...provided,
                                                                            fontsize: '12px'
                                                                        }),
                                                                        control: (provided, state) => {
                                                                            return ({
                                                                                ...provided,
                                                                                _focusVisible: {
                                                                                    boxShadow: '0 0 0 1px #ecc94b'
                                                                                }
                                                                            })
                                                                        },
                                                                    }}
                                                                    selectedOptionColorScheme='yellow'
                                                                    options={[
                                                                        {
                                                                            label: "Select...",
                                                                            value: null,
                                                                        },
                                                                        {
                                                                            label: "Gram",
                                                                            value: "gram",
                                                                        },
                                                                        {
                                                                            label: "Buah",
                                                                            value: "buah",
                                                                        },
                                                                        {
                                                                            label: "ML",
                                                                            value: "ml",
                                                                        },
                                                                    ]}
                                                                    value={v?.jumlahBahanAktif} 
                                                                    onChange={e => handleChangeInfo(areaIndex, i, 'jumlahBahanAktif', e, localDataServiceTreatment)}
                                                                />
                                                            </FormControl>
                                                        }

                                                        {
                                                            v?.jumlahBahanAktif && v?.bahanAktif && v?.bahanAktif?.label !== 'Tidak Ada' &&
                                                            <FormControl isInvalid={validate && areaValue?.temuanPest?.value === 'ada' && !v?.satuanBahanAktif}>
                                                                <FormLabel fontSize='12px'>Satuan {v?.jumlahBahanAktif?.label} ({v?.description}) Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                                                <Input 
                                                                    _focusVisible={{
                                                                        borderColor: 'yellow.400',
                                                                        boxShadow: '0 0 0 1px #ecc94b' 
                                                                    }}
                                                                    as={CurrencyInput}
                                                                    fontSize='12px' 
                                                                    onValueChange={e => handleChangeInfo(areaIndex, i, 'satuanBahanAktif', e, localDataServiceTreatment)}
                                                                    value={v?.satuanBahanAktif??0}
                                                                    decimalsLimit={2}
                                                                    decimalSeparator="," 
                                                                    groupSeparator="."
                                                                />
                                                            </FormControl>
                                                        }

                                                        <Divider />
                                                    </Fragment>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                    }
                                </Fragment>
                            }

                            {
                                areaValue?.temuanPest?.value === 'tidak' &&
                                <Fragment>
                                    <FormControl isInvalid={validate && areaValue?.temuanPest?.value === 'tidak' && !areaValue?.action}>
                                        <FormLabel fontSize='12px'>Action Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                        <Input 
                                            _focusVisible={{
                                                borderColor: 'yellow.400',
                                                boxShadow: '0 0 0 1px #ecc94b' 
                                            }}
                                            type='text' 
                                            fontSize='12px' 
                                            value={areaValue?.action} 
                                            onChange={e => handleChangeNoPestField(areaIndex, 'action', e?.target?.value, localDataServiceTreatment)}
                                        />
                                    </FormControl>

                                    <FormControl isInvalid={validate && areaValue?.temuanPest?.value === 'tidak' && !areaValue?.bahanAktif}>
                                        <FormLabel fontSize='12px'>Bahan Aktif Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                        <Select
                                            useBasicStyles
                                            chakraStyles={{
                                                input: (provided) => ({
                                                    ...provided,
                                                    fontsize: '12px'
                                                }),
                                                control: (provided, state) => {
                                                    return ({
                                                        ...provided,
                                                        _focusVisible: {
                                                            boxShadow: '0 0 0 1px #ecc94b'
                                                        }
                                                    })
                                                },
                                            }}
                                            selectedOptionColorScheme='yellow'
                                            options={[
                                                {
                                                    label: "Select...",
                                                    value: null,
                                                },
                                                ...lookup?.data?.bahan_aktif?.lookup_value?.map(v => {
                                                    return {
                                                        label: v?.description,
                                                        value: v?.id,
                                                    }
                                                })
                                            ]}
                                            value={areaValue?.bahanAktif}
                                            onChange={e => handleChangeNoPestField(areaIndex, 'bahanAktif', e, localDataServiceTreatment)}
                                        />
                                    </FormControl>

                                    {
                                        areaValue?.bahanAktif && areaValue?.bahanAktif?.label !== 'Tidak Ada' &&
                                        <FormControl isInvalid={validate && areaValue?.temuanPest?.value === 'tidak' && !areaValue?.jumlahBahanAktif}>
                                            <FormLabel fontSize='12px'>Jumlah Pestisida / Bahan Kimia / Device Yang Digunakan Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                            <Select
                                                useBasicStyles
                                                chakraStyles={{
                                                    input: (provided) => ({
                                                        ...provided,
                                                        fontsize: '12px'
                                                    }),
                                                    control: (provided, state) => {
                                                        return ({
                                                            ...provided,
                                                            _focusVisible: {
                                                                boxShadow: '0 0 0 1px #ecc94b'
                                                            }
                                                        })
                                                    },
                                                }}
                                                selectedOptionColorScheme='yellow'
                                                options={[
                                                    {
                                                        label: "Select...",
                                                        value: null,
                                                    },
                                                    {
                                                        label: "Gram",
                                                        value: "gram",
                                                    },
                                                    {
                                                        label: "Buah",
                                                        value: "buah",
                                                    },
                                                    {
                                                        label: "ML",
                                                        value: "ml",
                                                    },
                                                ]}
                                                value={areaValue?.jumlahBahanAktif}
                                                onChange={e => handleChangeNoPestField(areaIndex, 'jumlahBahanAktif', e, localDataServiceTreatment)}
                                            />
                                        </FormControl>
                                    }

                                    {
                                        areaValue?.jumlahBahanAktif && areaValue?.bahanAktif && areaValue?.bahanAktif?.label !== 'Tidak Ada' &&
                                        <FormControl isInvalid={validate && areaValue?.temuanPest?.value === 'tidak' && !areaValue?.satuanBahanAktif}>
                                            <FormLabel fontSize='12px'>Satuan {areaValue?.jumlahBahanAktif?.label} Area {areaIndex + 1}{areaValue?.area ? ` (${areaValue?.area})` : ''}</FormLabel>
                                            <Input 
                                                _focusVisible={{
                                                    borderColor: 'yellow.400',
                                                    boxShadow: '0 0 0 1px #ecc94b' 
                                                }}
                                                as={CurrencyInput} 
                                                fontSize='12px' 
                                                value={areaValue?.satuanBahanAktif??0} 
                                                onValueChange={e => {
                                                    let tempArea = [...area]
                                                    tempArea[areaIndex].satuanBahanAktif = e
                                                    setLocalDataServiceTreatment(() => {
                                                        return [...tempArea]
                                                    })
                                                }}
                                                decimalsLimit={2}
                                                decimalSeparator="," 
                                                groupSeparator="."
                                            />
                                        </FormControl>
                                    }
                                </Fragment>
                            }
                        </Fragment>
                    )
                })
            }

            {
                localDataServiceTreatment?.length <= 59 &&
                <Button
                    width='100%' 
                    fontSize='12px' 
                    colorScheme='yellow' 
                    variant='outline'
                    onClick={() => {
                        if (localDataServiceTreatment?.length >= 60) return

                        let newArea = {
                            area: '',
                            temuanPest: '',
                            spesies: [
                                { 
                                    selected: false,
                                    name: 'tikus', 
                                    tangkapanMax: 200, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'kecoa', 
                                    tangkapanMax: 200, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'nyamuk', 
                                    tangkapanMax: 100, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'semut', 
                                    tangkapanMax: 50, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'lalat', 
                                    tangkapanMax: 100, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'laba laba', 
                                    tangkapanMax: null, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'Cicak', 
                                    tangkapanMax: 100, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'burung', 
                                    tangkapanMax: 20, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'anjing', 
                                    tangkapanMax: 20, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'ular', 
                                    tangkapanMax: 30, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'kucing', 
                                    tangkapanMax: 20, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'ribollium sp', 
                                    tangkapanMax: 150, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'Lasioderma sp', 
                                    tangkapanMax: 150, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'Ephestia', 
                                    tangkapanMax: 150, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'Night Insect', 
                                    tangkapanMax: 100, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'Kelelawar', 
                                    tangkapanMax: 100, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'Trogoderma variabile', 
                                    tangkapanMax: 150, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'Stegobium (Drugstore/ Biscuit Beetle)', 
                                    tangkapanMax: 150, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'Cryptolestes (Flat grain beetle)', 
                                    tangkapanMax: 150, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                                { 
                                    selected: false,
                                    name: 'Sitophilus sp.', 
                                    tangkapanMax: 150, 
                                    tangkapan: [], 
                                    action: '',
                                    bahanAktif: '',
                                    satuanBahanAktif: '',
                                    jumlahBahanAktif: '',
                                },
                            ],
                            action: '',
                            bahanAktif: '',
                            satuanBahanAktif: '',
                            jumlahBahanAktif: '',
                        }

                        const spesies = lookup?.data?.spesies?.lookup_value
                        newArea.spesies = spesies?.map(vSpesies => {
                            return {
                                selected: false,
                                tangkapan: [], 
                                action: '',
                                bahanAktif: '',
                                satuanBahanAktif: '',
                                jumlahBahanAktif: '',
                                ...vSpesies,
                            }
                        })

                        setLocalDataServiceTreatment(prev => {
                            return [
                                ...prev,
                                newArea,
                            ]
                        })
                    }}
                >
                    Tambah Area
                </Button>
            }

            <Button
                id='update-service-treatment-state'
                visibility='hidden'
                onClick={() => {
                    console.log('update-service-treatment-state clicked')
                    setArea(() => {
                        return [...localDataServiceTreatment]
                    })
                }}
            >
                Update State Rekomendasi
            </Button>

            <Flex
                position='sticky'
                bottom='0'
                paddingY='8px'
                marginY='-8px'
                backgroundColor='white'
            >
                <Button
                    width='100%' 
                    fontSize='12px' 
                    colorScheme='yellow'
                    isLoading={saveServiceTreatment?.isLoading || saveResultAll?.isLoading}
                    onClick={() => {
                        // saveData().then(() => validateForm())
                        // saveDataAll().then(() => validateForm())
                        handleSetValidateTrue().then(() => handleSubmit())
                    }}
                >Simpan</Button>
            </Flex>
        </Flex>
    )
}

export default memo(ServiceTreatmentTab)